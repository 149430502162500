import { styled } from '@mui/system'
import { Grid as MuiGrid } from '@mui/material'

const shouldForwardProp = (prop) => !['message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })`
	${({ message, position, theme }) =>
		position === 'top' || !message
			? `
	border-bottom: 1px solid ${theme.palette.grey.light};
	box-shadow: 0 0 54px 0 rgba(0, 0, 0, 0.09);
`
			: null}
	background-color: ${({ theme }) => theme.palette.common.black};
`

const GridContainer = styled(MuiGrid)`
	padding: ${({ theme }) => theme.spacing(2, 3, '13px')};
	.mini-trigger {
		padding: 0;
		.MuiSvgIcon-root {
			font-size: ${({ theme }) => theme.typography.pxToRem(19)};
			color: ${({ theme }) => theme.palette.common.white} !important;
		}
	}
`

const GridItem = styled(MuiGrid)`
	flex-basis: 30%;
	max-width: 30%;
`

const GridItemCenter = styled(MuiGrid)`
	flex-basis: 40%;
	max-width: 40%;
`

const LogoWrapper = styled('div')`
	margin: 0 auto;
	height: 31px;
	width: 127px;
`

export { GridContainer, GridItem, GridItemCenter, Header, LogoWrapper }
