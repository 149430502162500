import { styled } from '@mui/system'

const LogoRoot = styled('div')`
	height: 100%;
`

const H1 = styled('h1')`
	margin-bottom: 0;
	margin-top: 0;
	height: 100%;
`

export { H1, LogoRoot }
